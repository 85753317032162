import './App.css';
import { HeroLayout3 } from "./ui-components";
import { Recipe } from './ui-components';
import { MarketingPricing } from "./ui-components";
import { RecipeCollectionx } from "./ui-components";
import { NavBar } from "./ui-components";


function App() {
  return (
    <div className="App">
      <NavBar/>
    {/* <HeroLayout3 />
    <MarketingPricing /> */}
    <flex >
    <RecipeCollectionx
      gap="0px"
      justifyContent="left"
    />
    </flex>
    </div>
  );
}

export default App;
