/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Recipe(props) {
  const { recipe, overrides, ...rest } = props;
  return (
    <Flex
      gap="31px"
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
      padding="15px 45px 15px 45px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Recipe")}
    >
      <Image
        width="100%"
        height="364.59px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        src={recipe?.image_url}
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Text
        fontFamily="Inter"
        fontSize="25px"
        fontWeight="bold"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        width="283px"
        height="24px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={recipe?.address}
        {...getOverrideProps(overrides, "Recipe name")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="center"
        width="286px"
        height="31px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        backgroundColor={
          recipe?.price && recipe?.price < "10000" ? "green" : "red"
        }
        children={recipe?.price}
        {...getOverrideProps(overrides, "19.99")}
      ></Text>
    </Flex>
  );
}
